<template>
  <ButtonsIcon
    :enable-hover="false"
    :breakpoints="store.iconBreakpoints"
    iconName="IconPip"
    @click="store.playerStore.requestPip"
  ></ButtonsIcon>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();
</script>

<style lang="scss" scoped></style>
