<template>
  <div
    class="z-0 absolute w-full h-full flex justify-center items-center cursor-pointer"
    @click="onOverlayClick"
  >
    <template v-if="isMdAndUp">
      <ButtonsCta
        class="variant-glass px-4"
        custom-class
        @click.stop="onIndicatorClick"
      >
        <PlayerOverlayControlsPlayIcon />
      </ButtonsCta>
    </template>
    <template v-else>
      <div
        :class="{ 'backdrop-blur-sm bg-white/[0.15]': canScrub }"
        class="p-4 flex items-center gap-x-7 rounded-full outline-none"
      >
        <ButtonsIcon
          v-if="canScrub"
          :enable-hover="false"
          :breakpoints="store.iconBreakpoints"
          iconName="IconSkipBackTen"
          @click.stop="store.playerStore.scrubBack"
        ></ButtonsIcon>
        <button
          class="rounded-full bg-brand-red w-[52px] h-[52px]"
          custom-class
          @click.stop="store.playerStore.togglePlayback()"
        >
          <PlayerOverlayControlsPlayIcon size="42" />
        </button>
        <ButtonsIcon
          v-if="canScrub"
          :enable-hover="false"
          :breakpoints="store.iconBreakpoints"
          iconName="IconSkipForwardTen"
          @click.stop="store.playerStore.scrubForward"
        ></ButtonsIcon>
      </div>
    </template>
  </div>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";

const { isMdAndUp } = useBreakpoints();
const store = usePlayerUi();
const isAlreadyShowing = ref(false);

const props = defineProps({
  canScrub: {
    type: Boolean,
    default: () => true,
  },
  emitInstead: Boolean,
});

const emit = defineEmits("on-indicator-click");

const onOverlayClick = (e) => {
  const { episodeList, songGallery } = store.actionableState;
  if (episodeList || songGallery) {
    store.actionableState.songGallery = false;
    store.actionableState.episodeList = false;
    store.preventHideUI = false;
    store.hideUi();
    return;
  }

  if (isAlreadyShowing.value) {
    onIndicatorClick();
  }

  isAlreadyShowing.value = true;
};

const onIndicatorClick = () => {
  if (props.emitInstead) {
    emit("on-indicator-click");
    return;
  }
  store.playerStore.togglePlayback();
};

watch(
  () => store.isUiShowing,
  (isUiShowing) => {
    if (!isUiShowing) {
      isAlreadyShowing.value = false;
    }
  }
);
</script>

<style lang="scss" scoped></style>
