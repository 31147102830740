import { defineStore } from "pinia";
import { PLAYERS } from "@/constants";
import { onKeyStroke } from "@vueuse/core";

export const usePlayerUi = defineStore("playerUi", {
  state: () => ({
    playerId: null,

    isLoading: true,

    // flag to store whether the ui is showing or not
    isUiShowing: false,

    // used when mouse is hovering over any of the controls
    preventHideUI: false,

    /**
     * flags for different states for actionable section of the player
     */
    actionableState: {
      nextEpisode: false,
      nextEpisodeCountdown: false,
      nextSongCountdown: false,
      episodeList: false,
      skipIntro: false,
      infoModal: false,
      songGallery: false,
    },
    /**
     * timers
     */
    actionableTimers: {
      nextEpisode: null,
      hideTimeout: null,
    },

    keyboardListenerRegistered: false,
  }),

  getters: {
    playerStore() {
      return useAllPlayers().getStore(this.playerId);
    },

    /**
     * from the actionable state (wc is an object of flags)
     * find the value that's true, and return
     */
    hoveredState() {
      return Object.keys(this.actionableState).find(
        (key) => this.actionableState[key] === true
      );
    },

    playIconName() {
      if (!this.playerStore) return;
      if (this.playerId == PLAYERS.LIVE_PLAYER) {
        return this.playerStore.playbackState == "playing"
          ? "IconStopSquare"
          : "IconPlay";
      }
      if (this.playerId == PLAYERS.SONG_PLAYER) {
        switch (this.playerStore.playbackState) {
          case "playing":
            return "IconPause";
          case "paused":
            return "IconPlay";
          case "ended":
            return "IconReplay";

          default:
            return "IconPlay";
        }
      }

      return this.playerStore.playbackState == "playing"
        ? "IconPause"
        : "IconPlay";
    },

    iconBreakpoints() {
      return { lg: 40, md: 32, sm: 24 };
    },

    volumeIcon() {
      if (!this.playerStore) return;

      if (this.playerStore.volume === 0) {
        return "IconVolumeOff";
      } else if (this.playerStore.volume < 0.5) {
        return "IconVolumeLow";
      } else if (this.playerStore.volume >= 0.5) {
        return "IconVolumeHigh";
      }
    },

    fullscreenIcon() {
      if (!this.playerStore) return;
      return this.playerStore.isFullscreen
        ? "IconFullscreenExit"
        : "IconFullscreenEnter";
    },
  },

  actions: {
    setPlayer(playerId) {
      this.playerId = playerId;
    },
    resetActionableContent(except) {
      if (except) {
        Object.keys(this.actionableState).forEach((key) => {
          if (except == key) return;
          this.actionableState[key] = false;
        });
      } else {
        Object.assign(this.actionableState, {
          nextEpisode: false,
          nextEpisodeCountdown: false,
          nextSongCountdown: false,
          episodeList: false,
          skipIntro: false,
          infoModal: false,
          songGallery: false,
        });
      }
    },

    hideUi() {
      if (this.preventHideUI || this.isLoading) return;
      clearTimeout(this.hideTimeout); // Clear any existing timeout.
      this.hideTimeout = setTimeout(() => {
        this.hideTimeout && clearTimeout(this.hideTimeout);
        this.$patch({ isUiShowing: false });
      }, 3000);
    },

    /**
     * first get ratio of seconds in relation to the width of the bar
     * then based on the relative x click event, calculate the number
     * of seconds corresponding to the click position on the bar
     * @param {int} relX: clickEvent.clientX
     */
    calcRelXToSeconds(relX) {
      const secondsPerPixel = this.player.duration / this.overbarWidth;
      return relX * secondsPerPixel;
    },

    registerKeyboardEvents() {
      // if (this.keyboardListenerRegistered) return;

      onKeyStroke(["ArrowLeft", "a"], (e) => {
        this.isUiShowing = true;
        this.playerStore.scrubBack();
        this.hideUi();
      });

      onKeyStroke(["ArrowRight", "d"], (e) => {
        this.isUiShowing = true;
        this.playerStore.scrubForward();
        this.hideUi();
      });

      onKeyStroke([" ", "Spacebar", "Space Bar"], (e) => {
        e.preventDefault();
        this.isUiShowing = true;
        this.playerStore.togglePlayback();
        this.hideUi();
      });

      onKeyStroke(["ArrowDown", "s"], (e) => {
        this.isUiShowing = true;
        this.playerStore.stepVolume(-0.1);
        this.hideUi();
      });

      onKeyStroke(["ArrowUp", "w"], (e) => {
        this.isUiShowing = true;
        this.playerStore.stepVolume(0.1);
        this.hideUi();
      });

      onKeyStroke(["m"], (e) => {
        this.isUiShowing = true;
        this.playerStore.toggleMute();
        this.hideUi();
      });

      document.addEventListener("fullscreenchange", (e) => {
        if (document.fullscreenElement) {
          this.playerStore.isFullscreen = true;
        } else {
          this.playerStore.isFullscreen = false;
        }
      });

      console.info("[🕹️ PlayerStore]️ registered keyboard events");
      this.keyboardListenerRegistered = true;
    },
  },
});
