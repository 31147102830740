<template>
  <div class="flex justify-between items-center">
    <div class="flex gap-x-3 md:gap-x-4 lg:gap-x-6 items-center">
      <slot name="controls-start"> </slot>
    </div>
    <div class="flex-1 flex justify-center gap-x-3 md:gap-x-4 lg:gap-x-6 items-center">
      <slot name="controls-center"></slot>
    </div>
    <div class="flex justify-end gap-x-3 md:gap-x-4 lg:gap-x-6 items-center">
      <slot name="controls-end"></slot>
    </div>
  </div>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();
</script>

<style lang="scss" scoped></style>
