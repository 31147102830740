<template>
  <tippy
    :arrow="false"
    theme="blur"
    interactive
    :onShown="useHideTippyOnChildrenClick"
    trigger="mouseenter focus click"
  >
    <ButtonsIcon
      :enable-hover="false"
      :breakpoints="store.iconBreakpoints"
      iconName="IconVideoSettings"
    ></ButtonsIcon>
    <template #content>
      <TippyMenu>
        <div class="w-full flex flex-col">
          <span
            class="bg-white/10 text-body-big text-white opacity-[0.87] text-center px-6 py-4"
          >
            {{ $t("Quality") }}
          </span>
          <div
            v-for="(q, key) in qualities"
            :key="key"
            :class="[{ 'bg-white/30': store.playerStore.quality == key }]"
            class="px-6 py-3 flex justify-center items-center gap-x-4 cursor-pointer"
            @click="store.playerStore.setQuality(q.id)"
          >
            <span class="text-body-big text-center text-white opacity-[0.87]">
              {{ q?.label }}
            </span>
          </div>
        </div>
      </TippyMenu>
    </template>
  </tippy>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();

const props = defineProps({
  iconSize: Number,
  qualities: { type: Array, required: true },
  modelValue: Number,
});

const emit = defineEmits(["update:modelValue"]);

const qualities = computed(() => {
  let list = [];

  if (store.playerStore.qualities) {
    list = store.playerStore.qualities.map((q) => ({
      ...q,
      label: `${q.height}p`,
    }));
  }

  list.push({ id: -1, label: "Auto" });

  return list;
});

const onQualityChange = (quality) => {
  emit("update:modelValue", quality);
};
</script>

<style lang="scss" scoped></style>
