<template>
  <template v-if="store.playerId == PLAYERS.SONG_PLAYER">
    <Icon
      v-if="store.playerStore.playbackState == 'playing'"
      :size="parsedSize"
      name="IconPause"
      class="fill-white"
    ></Icon>
    <Icon
      v-if="store.playerStore.playbackState == 'paused'"
      :size="parsedSize"
      name="IconPlay"
      class="fill-white"
    ></Icon>
    <Icon
      v-if="store.playerStore.playbackState == 'ended'"
      :size="parsedSize"
      name="IconReplay"
      class="fill-white"
    ></Icon>
    <Icon
      v-if="store.playerStore.playbackState == 'buffering'"
      :size="parsedSize"
      name="IconSpinner"
      class="fill-white animate-spin"
    ></Icon>
  </template>
  <template v-else-if="store.playerId == PLAYERS.LIVE_PLAYER">
    <Icon
      v-if="store.playerStore.playbackState == 'playing'"
      :size="parsedSize"
      name="IconStopSquare"
      class="fill-white"
    ></Icon>
    <Icon
      v-else-if="store.playerStore.playbackState == 'buffering'"
      :size="parsedSize"
      name="IconSpinner"
      class="fill-white animate-spin"
    ></Icon>
    <Icon v-else :size="parsedSize" name="IconPlay" class="fill-white"></Icon>
  </template>
  <template v-else>
    <Icon
      v-if="store.playerStore.playbackState == 'ended'"
      :size="parsedSize"
      name="IconReplay"
      class="fill-white"
    ></Icon>
    <Icon
      v-else-if="store.playerStore.playbackState == 'playing'"
      :size="parsedSize"
      name="IconPause"
      class="fill-white"
    ></Icon>
    <Icon v-else :size="parsedSize" name="IconPlay" class="fill-white"></Icon>
  </template>
</template>

<script setup>
import { PLAYERS } from "@/constants";
import { usePlayerUi } from "@/stores/playerUi";

const { breakpoints: bp } = useBreakpoints();

const props = defineProps({
  parsedSize: Object,
  size: String,
});

const store = usePlayerUi();

const parsedSize = computed(() => {
  if (props.size) return props.size;

  if (bp.greaterOrEqual("lg").value) {
    return store.iconBreakpoints.lg;
  } else if (bp.greaterOrEqual("md").value) {
    return store.iconBreakpoints.md;
  } else {
    return store.iconBreakpoints.sm;
  }
});
</script>

<style lang="scss" scoped></style>
