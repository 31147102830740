<template>
  <div
    class="flex"
    @click.stop=""
    @mouseenter="isShowing = true"
    @mouseleave="isShowing = false"
  >
    <ButtonsIcon
      :enable-hover="false"
      :breakpoints="store.iconBreakpoints"
      :icon-name="volumeIcon"
      @click="store.playerStore?.toggleMute"
    ></ButtonsIcon>

    <TransitionRoot
      :show="isShowing"
      as="template"
      enter="transform transition ease-in-out duration-200"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transform transition ease-in-out duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <input
        type="range"
        min="0"
        max="1"
        step="0.1"
        class="accent-white"
        v-model="store.playerStore.player.volume"
        @change="handleVolumeChange"
      />
    </TransitionRoot>
  </div>
</template>

<script setup>
import { TransitionRoot } from "@headlessui/vue";
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();
const isShowing = ref(false);
const volumeIcon = ref(store.volumeIcon);

watch(
  [() => store.playerStore?.volume, store.playerStore?.isMute],
  async ([newVolume, newMute], [_, __]) => {
    if (newMute === true || newVolume === 0) {
      volumeIcon.value = "IconVolumeOff";
      return;
    }

    if (!newMute) {
      if (newVolume < 0.5) {
        volumeIcon.value = "IconVolumeLow";
      } else if (newVolume >= 0.5) {
        volumeIcon.value = "IconVolumeHigh";
      }
    }
  }
);
</script>

<style lang="scss" scoped></style>
