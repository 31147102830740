<template>
  <div
    class="player-overlay"
    @pointerenter.once="onPointerenter"
    @pointerdown.self.stop="onPointerdown"
    @pointermove="onPointermove"
    @touchstart="onPointerdown"
    @touchend="onTouchend"
    @click="onPointerdown"
    @dblclick="onDoubleClick"
  >
    <!-- <TransitionRoot
      appear
      :show="true"
      as="template"
      enter="transform transition duration-200"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transform transition duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      
    </TransitionRoot> -->
    <div
      :class="[store.isUiShowing ? 'opacity-100' : 'opacity-0']"
      class="w-full h-full duration-200"
    >
      <div
        class="z-0 overlay__gradients absolute top-0 start-0 w-full h-full touch-none pointer-events-none"
      >
        <div class="w-1/2 h-full gradient horizontal rtl:-scale-x-100"></div>
        <div
          class="w-full h-1/2 absolute bottom-0 start-0 gradient vertical"
        ></div>
      </div>
      <div
        class="overlay__header absolute z-20 w-full p-4 pb-3 md:p-6 lg:px-9 lg:pt-6 flex flex-col gap-y-4 md:gap-y-5 touch-none pointer-events-none"
      >
        <div class="overlay__header-1 flex justify-between">
          <slot name="header1-start"></slot>
          <slot name="header1-end"></slot>
        </div>
        <div class="overlay__header-2 flex justify-between">
          <slot name="header2-start"></slot>
          <slot name="header2-end"></slot>
        </div>
      </div>
      <div
        class="z-0 overlay__indicator absolute top-0 start-0 w-full h-full flex justify-center items-center"
        @click="onClick"
      >
        <slot name="indicator">
          <Icon
            v-if="showLoading"
            name="IconSpinner"
            size="48"
            class="animate-spin"
          ></Icon>
          <PlayerOverlayPlaybackIndicator v-else :can-scrub="canScrub" />
        </slot>
      </div>
    </div>
    <div
      v-if="store.playerStore?.player"
      class="absolute bottom-0 w-full flex flex-col gap-y-3"
    >
      <!-- <TransitionRoot
        appear
        :show="store.isUiShowing || store.hoveredState != null"
        as="template"
        enter="transform transition duration-200"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transform transition duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
       
      </TransitionRoot> -->
      <div>
        <slot name="actionables"></slot>
      </div>

      <div
        :class="[store.isUiShowing ? 'opacity-100' : 'opacity-0']"
        class="flex flex-col gap-y-4 duration-200"
        dir="ltr"
      >
        <slot name="scrubber"></slot>
        <PlayerOverlayControls
          class="overlay__controls flex justify-between items-center px-4 md:px-6 lg:px-10 pb-4"
        >
          <template #controls-start>
            <slot name="controls-start"></slot>
          </template>
          <template #controls-center>
            <slot name="controls-center"></slot>
          </template>
          <template #controls-end>
            <slot name="controls-end"></slot>
          </template>
        </PlayerOverlayControls>
      </div>

      <!-- <TransitionRoot
        appear
        :show="store.isUiShowing"
        as="template"
        enter="transform transition duration-200"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transform transition duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
       
      </TransitionRoot> -->
    </div>
  </div>
</template>

<script setup>
import { TransitionRoot } from "@headlessui/vue";
import { usePlayerUi } from "@/stores/playerUi";

const props = defineProps({
  canScrub: {
    type: Boolean,
    default: () => true,
  },
});
const store = usePlayerUi();

/**
 * show loading icon only when:
 * isFetchingData - when the title is still fetching its data
 * isBuffering - from the sdk, the playbackstate is buffering
 * hasBeenActive - from the browser, if the user has interacted with it
 */
const showLoading = computed(() => {
  const isBuffering = store.playerStore?.playbackState == "buffering";
  const isFetchingData = store.isLoading;
  const hasBeenActive = navigator.userActivation?.hasBeenActive || false;

  const output = isFetchingData || (isBuffering && hasBeenActive);

  return output;
});

/**
 * onPointerenter rules
 * should only work on the mouse
 * show ui
 */
const onPointerenter = ({ pointerType }) => {
  if (pointerType == "touch") return;
  store.isUiShowing = true;
  store.hideUi();
};

/**
 * onPointerdown rules
 * onTouch:
 * toggle the ui
 *
 * onMouse:
 * togglePlayback
 */
const onPointerdown = (e) => {
  if (e.type == "touchstart") {
    console.info("touchstart!");
    store.isUiShowing = true;
  }
};

const onTouchend = () => {
  if (store.isUiShowing) {
    store.hideUi();
  }
};

/**
 * onPointermove rules
 * should only work on the mouse
 * show ui
 */
const onPointermove = ({ pointerType }) => {
  if (pointerType == "touch") return;
  store.isUiShowing = true;

  store.hideUi();
};

const onDoubleClick = () => {
  store.playerStore.toggleFullscreen();
};
</script>

<style lang="scss" scoped></style>
