<template>
  <ButtonsIcon
    :enable-hover="false"
    :breakpoints="store.iconBreakpoints"
    :icon-name="store.fullscreenIcon"
    @click="store.playerStore.toggleFullscreen"
  ></ButtonsIcon>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();
</script>

<style lang="scss" scoped></style>
