<template>
  <div
    class="bg-white/10 border border-white/80 rounded-md"
  >
    <slot></slot>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
